import './src/scss/theme.scss'
import('../../src/js/app')

require('owl.carousel')

$(document).ready(function () {
  $('.calendar .owl-carousel').owlCarousel({
    loop: true,
    margin: 4,
    nav: true,
    dots: false,
    stagePadding: 30,
    navText: [
      '<i class=\'fa fa-fw fa-chevron-left\'></i>',
      '<i class=\'fa fa-fw fa-chevron-right\'></i>'
    ],
    autoplay: true,
    autoplayHoverPause: true,
    items: 1
  })
})
